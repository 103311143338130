import { useState } from "react";

const useToggle = (
  initialState?: boolean
): [state: boolean, toggle: () => void] => {
  const [state, setState] = useState(initialState ?? false);
  const toggle = () => setState(!state);
  return [state, toggle];
};

export default useToggle;
