import "./NavBar.scss";
import { ReactComponent as Logo } from "assets/Logo_vector.svg";
import { ReactComponent as Hamburger } from "assets/Hamburger.svg";
import { NavBarProps } from "components/NavBar/types";
import cn from "classnames";
import useToggle from "hooks/useToggle";
import { toNavItems } from "components/NavBar/NavItem";
import useScrollPosition from "hooks/useScrollPosition";

const NavBar = ({ navitemConfigs }: NavBarProps) => {
  const [overflowMenuVisible, toggleOverflowMenu] = useToggle(false);
  const scrollPosition = useScrollPosition();

  return (
    <div className={`navbar ${scrollPosition > 20 ? "scrolled" : ""}`}>
      <a href={"/"} className={"branding"}>
        <Logo className={"logo"} style={{ width: "80px" }} />
        <p className={"text"}>{"Global Family\nOffice Network"}</p>
      </a>

      <div className={"hamburger"} onClick={toggleOverflowMenu}>
        <Hamburger />
      </div>

      <div className={cn("mobile-nav", { open: overflowMenuVisible })}>
        <nav>
          <ul>{toNavItems(navitemConfigs)}</ul>
        </nav>
      </div>

      <div className={"desktop-nav"}></div>
    </div>
  );
};

export default NavBar;
