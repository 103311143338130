import React, { Suspense } from "react";
import "styles/App.scss";
import NavBar from "components/NavBar";
import { NavItemConfig } from "components/NavBar/types";
import Footer from "components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const HomePage = React.lazy(() => import("pages/HomePage"));
const WhoWeAre = React.lazy(() => import("pages/WhoWeAre"));
const OurMission = React.lazy(() => import("pages/OurMission"));
const WhatWeDo = React.lazy(() => import("pages/WhatWeDo"));
const OurMembers = React.lazy(() => import("pages/OurMembers"));
const MembershipBenefits = React.lazy(() => import("pages/MembershipBenefits"));
const ValueProposition = React.lazy(() => import("pages/ValueProposition"));
const MembersMarketplace = React.lazy(() => import("pages/MembersMarketplace"));
const Training = React.lazy(() => import("pages/Training"));
const Events = React.lazy(() => import("pages/Events"));
const OurPartners = React.lazy(() => import("pages/OurPartners"));
const DealFlow = React.lazy(() => import("pages/DealFlow"));
const ContactUs = React.lazy(() => import("pages/ContactUs"));
const Error = React.lazy(() => import("pages/Error"));

const NAV_ITEMS: NavItemConfig[] = [
  {
    name: "About Us",
    children: [
      {
        name: "Who We Are",
        to: "/whoweare",
      },
      {
        name: "Our Mission",
        to: "/ourmission",
      },
      {
        name: "What We Do",
        to: "/whatwedo",
      },
    ],
  },
  {
    name: "Membership",
    children: [
      {
        name: "Our Members",
        to: "/ourmembers",
      },
      {
        name: "Membership Benefits",
        to: "/membershipbenefits",
      },
      {
        name: "Value Proposition",
        to: "/valueproposition",
      },
    ],
  },
  {
    name: "Training & Events",
    children: [
      {
        name: "Training",
        to: "/training",
      },
      {
        name: "Events",
        to: "/events",
      },
    ],
  },
  {
    name: "Our Partners",
    to: "/ourpartners",
  },
  {
    name: "Members Marketplace",
    to: "/membersmarketplace",
  },
  {
    name: "Deal Flow",
    to: "/dealflow",
  },
  {
    name: "Contact Us",
    to: "/contactus",
  },
];

const App = () => {
  return (
    <div className="App">
      <NavBar navitemConfigs={NAV_ITEMS} />
      <Suspense fallback={<div>loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<HomePage />} />
            <Route path={"home"} element={<HomePage />} />
            <Route path={"whoweare"} element={<WhoWeAre />} />
            <Route path="/ourmission" element={<OurMission />} />
            <Route path="/whatwedo" element={<WhatWeDo />} />
            <Route path="/ourmembers" element={<OurMembers />} />
            <Route
              path="/membershipbenefits"
              element={<MembershipBenefits />}
            />
            <Route path="/valueproposition" element={<ValueProposition />} />
            <Route
              path="/membersmarketplace"
              element={<MembersMarketplace />}
            />
            <Route path="/training" element={<Training />} />
            <Route path="/events" element={<Events />} />
            <Route path="/ourpartners" element={<OurPartners />} />
            <Route path="/dealflow" element={<DealFlow />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route element={<Error />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
      <Footer />
    </div>
  );
};

export default App;
