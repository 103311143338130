import React from "react";

type Props = {};

const Footer = (props: Props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      backgroundColor: "#2F4D77",
      padding: "0 5px",
      clear: "both",
      position: "absolute",
      height: "21px",
      bottom: "0",
      width: "100%",
    }}
  >
    <p style={{ fontSize: "12px", color: "white", margin: "0" }}>
      Copyright © GFON. All Rights Reserved
    </p>
  </div>
);

export default Footer;
