import { NavItemConfig } from "components/NavBar/types";
import useToggle from "hooks/useToggle";
import { ReactComponent as Chevron } from "assets/Chevron1.svg";

const NavItem = ({ name, to, children }: NavItemConfig) => {
  const [isExpanded, expand] = useToggle(false);

  return (
    <li key={name} onClick={children ? expand : undefined}>
      <a href={to} className={"nav-item-title"}>
        {name}
        {children ? <Chevron className={"chevron"} /> : null}
      </a>

      {children && isExpanded && (
        <ul className={"sub-list"}>{toNavItems(children)}</ul>
      )}
    </li>
  );
};

const toNavItems = (navItemConfigs: NavItemConfig[]) =>
  navItemConfigs.map((navItemProps) => <NavItem {...navItemProps} />);

export { NavItem, toNavItems };
